import React from "react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Switch,
  Divider,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const AdditionalSettings = () => {
  const navigate = useNavigate();

  return (
    <Box style={{ maxWidth: 560, padding: "10px" }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" ml={2}>
        Additional Settings
        </Typography>
      </Box>
      <List sx={{ padding: "1rem 1rem 0 1rem" }}>
        <Typography variant="subtitle1" ml={1}>
          Notifications
        </Typography>
        <ListItem style={{ padding: "10px" }}>
          <ListItemText
            primary={
              <Typography variant="body2" sx={{ fontSize: "12px" }}>
                Someone Posted an alert
              </Typography>
            }
          />
          <Switch edge="end" size="small" />
        </ListItem>
        <ListItem style={{ padding: "10px" }}>
          <ListItemText
            primary={
              <Typography variant="body2" sx={{ fontSize: "12px" }}>
                My contact posted an alert
              </Typography>
            }
          />
          <Switch edge="end" size="small" />
        </ListItem>
        <ListItem style={{ padding: "10px" }}>
          <ListItemText
            primary={
              <Typography variant="body2" sx={{ fontSize: "12px" }}>
                Location request on my alert post
              </Typography>
            }
          />
          <Switch edge="end" size="small" />
        </ListItem>
        <ListItem style={{ padding: "10px" }}>
          <ListItemText
            primary={
              <Typography variant="body2" sx={{ fontSize: "12px" }}>
                My location request was permitted
              </Typography>
            }
          />
          <Switch edge="end" size="small" />
        </ListItem>
        <ListItem style={{ padding: "10px", marginBottom: "10px" }}>
          <ListItemText
            primary={
              <Typography variant="body2" sx={{ fontSize: "12px" }}>
                New post on my local live feed
              </Typography>
            }
          />
          <Switch edge="end" size="small" />
        </ListItem>
        <Divider />
        <Typography variant="subtitle1" mt={3} ml={1}>
          Enable / Disable
        </Typography>
        <ListItem style={{ padding: "10px" }}>
          <ListItemText
            primary={
              <Typography variant="body2" sx={{ fontSize: "12px" }}>
                Someone posted an alert
              </Typography>
            }
          />
          <Switch edge="end" size="small" />
        </ListItem>
        <ListItem style={{ padding: "10px" }}>
          <ListItemText
            primary={
              <Typography variant="body2" sx={{ fontSize: "12px" }}>
                My contact posted an alert
              </Typography>
            }
          />
          <Switch edge="end" size="small" />
        </ListItem>
      </List>
    </Box>
  );
};

export default AdditionalSettings;
