import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Protected(props) {
  const navigate = useNavigate();
  const { Component } = props;

  useEffect(() => {
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");

    if (accessToken && tokenType && client && expiry && uid) {
      const nowTime = new Date().getTime();
      const expiryTime = new Date(expiry).getTime();

      if (nowTime > expiryTime) {
        localStorage.clear();
        alert("Session expired");
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
      <Component />
    </>
  );
}

export default Protected;
