/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Avatar,
  TextField,
  Paper,
  Typography,
  IconButton,
  InputAdornment,
  Box,
  CircularProgress,
} from "@mui/material";
import MicOutlinedIcon from "@mui/icons-material/MicOutlined";
import SendIcon from "@mui/icons-material/Send";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";

const ChatWindow = ({ chat }) => {
  const [description, setDescription] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchMessages = () => {
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");

    axios
      .get(
        `https://api.locaided.com/v1/users/${chat.user_id}/direct_messages`,
        {
          headers: {
            "access-token": accessToken,
            "token-type": tokenType,
            client: client,
            expiry: expiry,
            uid: uid,
          },
        }
      )
      .then((response) => {
        console.log(response.data.data[0].chats_array);
        setMessages(response.data.data[0].chats_array);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching chats:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchMessages();
  }, [chat.user_id]);

  const handleSubmit = async () => {
    const url = `https://api.locaided.com/v1/users/${chat.user_id}/direct_message`;
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");

    if (!description.trim()) return;

    try {
      const response = await axios.post(
        url,
        {
          description: description,
        },
        {
          headers: {
            "access-token": accessToken,
            "token-type": tokenType,
            client: client,
            expiry: expiry,
            uid: uid,
          },
        }
      );
      fetchMessages();
      console.log("Message sent successfully:", response.data);
      setDescription("");
    } catch (error) {
      console.error(
        "Error sending message:",
        error.response ? error.response.data : error.message
      );
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CircularProgress size={50} sx={{ color: "#FD445D" }} />
        <Typography variant="h6" style={{ marginTop: "18px" }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ height: "95vh", display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", alignItems: "center", padding: 2 }}>
        <Avatar
          alt={chat.opposite_user_full_name}
          src={chat.opposite_user_profile_image.sharable}
        />
        <Typography variant="body2" sx={{ fontWeight: "bold", ml: 2 }}>
          {chat.opposite_user_full_name}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          p: 2,
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1",
            borderRadius: "4px",
          },
        }}
      >
        {messages.map((message, index) => (
          <Paper
            key={index}
            sx={{
              p: 2,
              mb: 2,
              bgcolor:
                message.current_user_id === message.sender_id
                  ? "#ff2557"
                  : "#F5F5F5",
              color:
                message.current_user_id === message.sender_id ? "#FFF" : "#000",
              alignSelf:
                message.current_user_id === message.sender_id
                  ? "flex-end"
                  : "flex-start",
              maxWidth: "70%",
            }}
          >
            <Typography
              variant="body2"
              style={{
                fontSize: "13px",
              }}
            >
              {message.description}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              style={{
                textAlign: "right",
                fontSize: "12px",
                color:
                  message.current_user_id === message.sender_id
                    ? "#FFF"
                    : "#000",
              }}
            >
              {message.time}
            </Typography>
          </Paper>
        ))}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
        <TextField
          variant="outlined"
          placeholder="Type message..."
          fullWidth
          sx={{ flex: 1, mr: 2, borderRadius: "15px", fontSize: "12px" }}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          InputProps={{
            sx: { borderRadius: "15px", fontSize: "12px" },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton sx={{ color: "#FFF" }}>
                  <CameraAltOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <IconButton
          sx={{ color: "#fff", p: 1, backgroundColor: "#ff2557" }}
          onClick={handleSubmit}
        >
          {description.trim() ? <SendIcon /> : <MicOutlinedIcon />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default ChatWindow;
