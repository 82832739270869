import React, { useState } from "react";
import { Grid, Divider } from "@mui/material";
import ChatList from "./ChatList";
import ChatWindow from "./ChatWindow";

const Chats = () => {
  const [selectedChat, setSelectedChat] = useState(null);

  const handleSelectChat = (chat) => {
    setSelectedChat(chat);
  };

  return (
    <Grid container spacing={2} justifyContent="flex-start">
      <Grid item xs={12} md={3} pl={0}>
        <ChatList onSelectChat={handleSelectChat} />
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item xs={12} md={7}>
        {selectedChat ? (
          <ChatWindow chat={selectedChat} />
        ) : (
          <div style={{ textAlign: "center" }}>
            Select a chat to start messaging
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default Chats;
