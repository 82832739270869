import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  InputAdornment,
  Link,
  Checkbox,
  Snackbar,
  Alert,
  FormControl,
  FormControlLabel,
  Box,
  IconButton,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { ReactComponent as LockSVG } from "../../Assets/lock.svg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useTheme } from "../../ThemeContext";
import logoLight from "../../Assets/white logo.png";
import logoDark from "../../Assets/white logo dark.png";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ff2557",
      light: "#ff83a3",
    },
    matteBlack: {
      main: "#1f1f1f",
    },
    grayish: {
      main: "#f4f4f4",
    },
  },
});

const Login = () => {
  const navigate = useNavigate();
  const { darkMode } = useTheme();
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    social_media_login: 1,
    login_with_google: true,
  });

  const handleCheckboxChange = (e) => {
    setIsAgreed(e.target.checked);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setError("Please Enter Email Address.");
      return;
    } else if (!password) {
      setError("Please Enter password.");
      return;
    }

    let save = { email, password };
    axios
      .post("https://api.locaided.com/auth/sign_in", save)
      .then((res) => {
        if (res.status === 200 && res.data.headers) {
          setEmail("");
          setPassword("");
          console.log("User Login Successfully!!!");
          const accessToken = res.data.headers["access-token"];
          const tokenType = res.data.headers["token-type"];
          const client = res.data.headers["client"];
          const expiry = res.data.headers["expiry"];
          const uid = res.data.headers["uid"];
          localStorage.setItem("access-token", accessToken);
          localStorage.setItem("token-type", tokenType);
          localStorage.setItem("client", client);
          localStorage.setItem("expiry", expiry);
          localStorage.setItem("uid", uid);
          navigate("/dashboard");
        } else {
          setError(res.data.message);
          setOpenSnackbar(true);
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });

    const accessToken = localStorage.getItem("access-token");
    if (!accessToken) {
      navigate("/login");
    }
  };

  const handleContinueWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user && user.access_token) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setFormData((prevFormData) => ({
            ...prevFormData,
            first_name: res.data.given_name,
            last_name: res.data.family_name,
            email: res.data.email,
          }));
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (formData.email !== "") {
      axios
        .post("https://api.locaided.com/auth/sign_in", formData)
        .then((res) => {
          if (res.status === 200 && res.data.headers) {
            console.log("User Login Successfully!!!");
            console.log(res);
            const accessToken = res.data.headers["access-token"];
            const tokenType = res.data.headers["token-type"];
            const client = res.data.headers["client"];
            const expiry = res.data.headers["expiry"];
            const uid = res.data.headers["uid"];
            localStorage.setItem("access-token", accessToken);
            localStorage.setItem("token-type", tokenType);
            localStorage.setItem("client", client);
            localStorage.setItem("expiry", expiry);
            localStorage.setItem("uid", uid);
            setFormData({
              first_name: "",
              last_name: "",
              email: "",
              social_media_login: 1,
              login_with_google: true,
            });
            navigate("/dashboard");
          } else {
            console.log("Error Message=>", res.data.message);
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
        });

      const accessToken = localStorage.getItem("access-token");
      if (!accessToken) {
        navigate("/login");
      }
    }
  }, [formData, navigate]);

  const handleContinueWithApple = () => {
    alert("Continue with Apple clicked");
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="100vw"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <div>
          <Box style={{ width: "100%", maxWidth: "380px", textAlign: "left" }}>
            <img
              src={darkMode ? logoLight : logoDark}
              alt="Locaided Logo"
              style={{
                width: "90%",
                maxWidth: "60%",
                marginTop: "1rem",
                height: "auto",
              }}
            />
            <Typography
              component="h6"
              variant="h4"
              sx={{
                fontWeight: "bold",
                fontFamily: "Urbanist",
                mt: "1.5rem",
                mb: "1rem",
                padding: "0",
              }}
            >
              Login to Your Account
            </Typography>
            {error && (
              <Typography color="error" gutterBottom>
                {error}
              </Typography>
            )}
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="email"
                      type="email"
                      autoComplete="email"
                      placeholder="Email Address"
                      value={email}
                      onChange={handleInputChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon sx={{ color: "#9E9E9E" }} />
                          </InputAdornment>
                        ),
                        sx: {
                          backgroundColor: theme.palette.grayish.main,
                          "&:focus": {
                            borderColor: theme.palette.primary.main,
                          },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          height: "3.5rem",
                        },
                        "& .MuiInputBase-input": {
                          padding: "10px 14px",
                        },
                        "& .MuiOutlinedInput-input::placeholder": {
                          fontFamily: "Urbanist",
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      autoComplete="current-password"
                      placeholder="Password"
                      value={password}
                      onChange={handleInputChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockSVG width="20px" height="20px" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={togglePasswordVisibility}
                              edge="end"
                              aria-label="toggle password visibility"
                              size="small"
                            >
                              {showPassword ? (
                                <VisibilityOff sx={{ color: "#9E9E9E" }} />
                              ) : (
                                <Visibility sx={{ color: "#9E9E9E" }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        sx: {
                          backgroundColor: theme.palette.grayish.main,
                          "&:focus": {
                            borderColor: theme.palette.primary.main,
                          },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          height: "3.5rem",
                        },
                        "& .MuiInputBase-input": {
                          padding: "10px 14px",
                        },
                        "& .MuiOutlinedInput-input::placeholder": {
                          fontFamily: "Urbanist",
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAgreed}
                        onChange={handleCheckboxChange}
                        sx={{
                          color: theme.palette.primary.main,
                          "& .Mui-checked": {
                            color: "#ff2557",
                          },
                        }}
                        inputProps={{ "aria-label": "agree to terms" }}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        textAlign="left"
                        fontFamily="Urbanist"
                      >
                        By registering, you agree to the{" "}
                        <Link href="/#" underline="hover" color="#2196F3">
                          Terms and Conditions,
                        </Link>{" "}
                        <Link href="/#" underline="hover" color="#2196F3">
                          Privacy Policy
                        </Link>
                        , and the{" "}
                        <Link href="/#" underline="hover" color="#2196F3">
                          use of cookies.
                        </Link>
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    style={{ textAlign: "left", fontFamily: "Urbanist" }}
                  >
                    Forgotten Password?{" "}
                    <span
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "#ff2557",
                      }}
                      onClick={() => navigate("/forgot")}
                    >
                      Click Here
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      fontFamily: "Urbanist",
                      borderRadius: "50px",
                      backgroundColor: isAgreed
                        ? theme.palette.primary.main
                        : theme.palette.primary.light,
                      color: "white",
                      "&:hover": {
                        backgroundColor: isAgreed
                          ? theme.palette.primary.light
                          : theme.palette.primary.main,
                      },
                      height: "3rem",
                    }}
                    disabled={!isAgreed}
                  >
                    <b>Login</b>
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{
                      borderRadius: "50px",
                      height: "3rem",
                      fontFamily: "Urbanist",
                      color: theme.palette.matteBlack.main,
                      backgroundColor: "#fff",
                    }}
                    startIcon={<AppleIcon />}
                    onClick={handleContinueWithApple}
                  >
                    <b>Continue with Apple</b>
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{
                      borderRadius: "50px",
                      height: "3rem",
                      fontFamily: "Urbanist",
                      color: theme.palette.grayish.main,
                      backgroundColor: "#0096FF",
                    }}
                    startIcon={<GoogleIcon />}
                    onClick={handleContinueWithGoogle}
                  >
                    <b>Continue with Google</b>
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ paddingLeft: "5px", paddingTop: "5px" }}
                >
                  <Typography
                    variant="body2"
                    mt="0.5rem"
                    textAlign="center"
                    fontFamily="Urbanist"
                  >
                    Already have an account?{" "}
                    <span
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "#ff2557",
                      }}
                      onClick={() => navigate("/signup")}
                    >
                      Sign Up
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </Box>
          <div className="container" style={{ marginTop: "1rem" }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Link
                  href="#"
                  color="inherit"
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="caption" fontFamily="Urbanist">
                    Terms and Conditions
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="#"
                  color="inherit"
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="caption" fontFamily="Urbanist">
                    Privacy Policy
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="#"
                  color="inherit"
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="caption" fontFamily="Urbanist">
                    Imprint
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="#"
                  color="inherit"
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="caption" fontFamily="Urbanist">
                    Cookie Policy
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </div>

          <Snackbar
            open={openSnackbar}
            autoHideDuration={5000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity="error"
              sx={{ width: "100%" }}
            >
              {error}
            </Alert>
          </Snackbar>
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
