import React, { useState, useEffect } from "react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import {
  Grid,
  CardMedia,
  Typography,
  IconButton,
  TextField,
  CircularProgress,
  Box,
} from "@mui/material";
import LiveFeedFilter from "../Layout/LiveFeedFilter";
import NavBar from "./Navbar";
import { ReactComponent as SearchIcon } from '../../Assets/search.svg';
import { ReactComponent as FilterIcon } from '../../Assets/filter.svg';

const LiveEvidences = () => {
  const { user } = useOutletContext();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchMessages = async () => {
    setLoading(true)
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");
    const lat = 31.47162;
    const long = 74.355574;

    try {
      const response = await axios.get("https://api.locaided.com/v1/messages/", {
        headers: {
          "access-token": accessToken,
          "token-type": tokenType,
          client: client,
          expiry: expiry,
          uid: uid,
        },
        params: {
          lat: lat,
          long: long,
        },
      });
      setMessages(response.data.data.messages);
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  return (
    <Grid container spacing={2} justifyContent="flex-start">
      <NavBar user={user} />
      <Grid item xs={12} md={6} p={2} m={2} bgcolor="#212330" sx={{borderRadius:"12px", maxWidth:560, width:600}}>
        <Box display="flex" alignItems="center" mb={2}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon width="16px" height="16px" />
                </IconButton>
              ),
              endAdornment: (
                <IconButton>
                  <FilterIcon width="20px" height="20px" />
                </IconButton>
              ),
            }}
          />
        </Box>
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <CircularProgress size={40} sx={{ color: "#FD445D" }} />
            <Typography variant="contain" style={{ marginTop: "18px" }}>
              Loading...
            </Typography>
          </Box>
        ) : (
          <Grid container mt={2} justifyContent="flex-start" >
            {messages.length > 0 ? (
              messages.map((message) => (
                message.image_url.sharable ? <Grid item xs={12} sm={12} md={6} lg={4} key={message.id}>
                  <CardMedia
                    component="img"
                    image={message.image_url.sharable}
                    alt="Concert"
                    sx={{
                      borderRadius: 0,
                      maxWidth: "100%",
                      height: "300px",
                    }}
                  />
                </Grid> : null
              ))
            ) : (
              <Typography variant="h6" color="#fff" mt={4}>
                No Evidences Yet
              </Typography>
            )}
          </Grid>)}
      </Grid>
      <Grid item xs={12} md={5}>
        <LiveFeedFilter />
      </Grid>
    </Grid>
  );
};

export default LiveEvidences;
