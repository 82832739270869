import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const AboutApp = () => {
  const navigate = useNavigate();

  return (
    <Box style={{ maxWidth: 560, padding: "10px" }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" ml={2}>
          About the App
        </Typography>
      </Box>
      <Box sx={{ padding: "1rem 1rem 0 1rem" }}>
        <Typography
          variant="subtitle2"
          ml={1}
          sx={{ fontSize: "13px", fontWeight: "bold" }}
        >
          Welcome to Locaided
        </Typography>
        <Typography variant="body2" ml={1} sx={{ fontSize: "13px" }}>
          Your World, Now Amplified.
          <br />
          Embrace the power of locality with Locaided - an app that redefines
          your connection with your surroundings. Bridging the gap that
          conventional social media platforms fail to address, Locaided delivers
          live updates and engages communities from a centralized locality in
          real-time. Cutting Through The Noise: Local Life, Amplified.
          <br />
          <br />
          Locaided is your direct line to the pulse of your community. Want to
          know about the weather conditions or traffic before heading out? Need
          to be in the loop about that new restaurant that's gaining traction or
          the weekend garage sale down the street? Perhaps you're interested in
          joining a local meetup or community fitness class? With Locaided, all
          these updates and more are at your fingertips through live, instant
          updates.
        </Typography>
        <Typography
          variant="subtitle2"
          mt={2}
          ml={1}
          sx={{ fontSize: "13px", fontWeight: "bold" }}
        >
          Instant Alert
        </Typography>
        <Typography variant="body2" ml={1} sx={{ fontSize: "13px" }}>
          Keeping Communities Safe.
          <br />
          Locaided advocates safety above all else, providing an instant alert
          system for emergencies. Whether it's an accident or a crime, our
          evidence live camera captures a time-stamped clip, enabling the timely
          exchange of crucial information. Red Flag: Tackling Fraud Head-On.
          <br />
          <br />
          We're stepping up against scams and fraud with Red Flag, a premium
          feature that lets users report suspicious activities across multiple
          platforms. Stay vigilant by checking reported activities and protect
          your community proactively. updates.
        </Typography>
        <Typography
          variant="subtitle2"
          mt={2}
          ml={1}
          sx={{ fontSize: "13px", fontWeight: "bold" }}
        >
          Your Data, Your Rules
        </Typography>
        <Typography variant="body2" ml={1} sx={{ fontSize: "13px" }}>
          At Locaided, we respect and safeguard your data privacy. Unlike
          others, we don't follow the practice of data selling. Your information
          remains yours.
        </Typography>
        <Typography
          variant="subtitle2"
          mt={2}
          ml={1}
          sx={{ fontSize: "13px", fontWeight: "bold" }}
        >
          Pro-Level Connections
        </Typography>
        <Typography variant="body2" ml={1} sx={{ fontSize: "13px" }}>
          To enhance your networking experience, our premium subscription allows
          you to broaden your contact list from 5 up to 100 contacts. Connect,
          interact, and engage at a scale never before possible, amplifying your
          reach within your local network.
          <br />
          Our revenue is driven by premium subscription options offering
          extended storage for PROOVS, private chat features, control over
          sharing PROOVS, and custom profile avatars. Let us enrich your
          experience as you form seamless connections like never before. Welcome
          to Locaided: the new-world of limitless possibilities.
        </Typography>
      </Box>
    </Box>
  );
};

export default AboutApp;
