import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  InputAdornment,
  Link,
  FormControl,
  Box,
  IconButton,
  createTheme,
  ThemeProvider,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Select,
  OutlinedInput,
  useMediaQuery,
  Snackbar,
  Alert,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import { ReactComponent as LockSVG } from "../../Assets/lock.svg";
import { ReactComponent as Flag } from "../../Assets/flag.svg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PhoneIcon from "@mui/icons-material/Phone";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useTheme } from "../../ThemeContext";
import logoLight from "../../Assets/white logo.png";
import logoDark from "../../Assets/white logo dark.png";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ff2557",
      light: "#ff83a3",
    },
    matteBlack: {
      main: "#1f1f1f",
    },
    grayish: {
      main: "#f4f4f4",
    },
  },
});

const SignupForm = () => {
  const navigate = useNavigate();
  const { darkMode } = useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  const [user, setUser] = useState(null);
  const [isAgreed, setIsAgreed] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [googleForm, setGoogleForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    social_media_login: 1,
    login_with_google: true,
  });
  const [userError, setUserError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    phone: "",
    gender: "",
    lat: "",
    long: "",
    code: "",
    date_of_birth: "",
    username: "",
    role: "regular",
    referral_code: "",
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFormData((prevFormData) => ({
            ...prevFormData,
            lat: position.coords.latitude,
            long: position.coords.longitude,
          }));
        },
        (error) => {
          setError("Error getting location. Please enable location services.");
          setOpenSnackbar(true);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
      setOpenSnackbar(true);
    }
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCheckboxChange = (e) => {
    setIsAgreed(e.target.checked);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "username") {
      try {
        const response = await axios.post("https://api.locaided.com/username", {
          username: value,
        });

        const { data } = response;

        if (data.message === "Username has already been taken") {
          setUserError("Username has Already been Taken");
          setError("Username has Already been Taken");
          setOpenSnackbar(true);
          setIsAgreed(false);
        } else {
          setUserError("");
          setOpenSnackbar(false);
          checkIfAgreed();
        }
      } catch (error) {
        console.error("Error checking username:", error);
        setOpenSnackbar(true);
      }
    }
    if (name === "email") {
      if (!validateEmail(value)) {
        setEmailError("Please enter a valid email address");
        setOpenSnackbar(true);
        setIsAgreed(false);
        return;
      }

      try {
        const response = await axios.post(
          "https://api.locaided.com/check_email",
          {
            email: value,
          }
        );

        const { data } = response;

        if (data.message === "Email has already been taken") {
          setEmailError("Email has Already been Taken");
          setError("Email has Already been Taken");
          setOpenSnackbar(true);
          setIsAgreed(false);
        } else {
          setEmailError("");
          checkIfAgreed();
          setOpenSnackbar(false);
        }
      } catch (error) {
        setError(error);
        setOpenSnackbar(true);
      }
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const checkIfAgreed = () => {
    if (userError === "" && emailError === "") {
      setIsAgreed(true);
    } else {
      setIsAgreed(false);
    }
  };

  const handleNext = () => {
    if (!formData.lat || !formData.long) {
      setError("Location is required. Please enable location services.");
      setOpenSnackbar(true);
    } else if (!formData.email) {
      setError("Please Enter Email Address.");
      setOpenSnackbar(true);
    } else if (!formData.password) {
      setError("Please Enter password.");
      setOpenSnackbar(true);
    } else if (formData.password !== formData.password_confirmation) {
      setError("Confirm password is Incorrect");
      setOpenSnackbar(true);
    } else {
      setError("");
      setOpenSnackbar(false);
      setCurrentPage(2);
    }
  };

  const handleBack = () => {
    setCurrentPage(1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.lat || !formData.long) {
      setError("Location is required. Please enable location services.");
      setOpenSnackbar(true);
    } else if (!formData.first_name) {
      setError("Please Enter First Name.");
      setOpenSnackbar(true);
    } else if (!formData.last_name) {
      setError("Please Enter Last Name.");
      setOpenSnackbar(true);
    } else if (!formData.username) {
      setError("Please Enter Username.");
      setOpenSnackbar(true);
    } else if (!formData.email) {
      setError("Please Enter Email Address.");
      setOpenSnackbar(true);
    } else if (!formData.password) {
      setError("Please Enter password.");
      setOpenSnackbar(true);
    } else if (formData.password !== formData.password_confirmation) {
      setError("Confirm password is Incorrect");
      setOpenSnackbar(true);
    } else if (!formData.code) {
      setError("Please Select Country Code");
      setOpenSnackbar(true);
    } else if (!formData.phone) {
      setError("Please Enter Phone Number");
      setOpenSnackbar(true);
    } else if (!formData.gender) {
      setError("Please Select your Gender.");
      setOpenSnackbar(true);
    } else if (!formData.date_of_birth) {
      setError("Please Enter Date of Birth.");
      setOpenSnackbar(true);
    } else {
      axios
        .post("https://api.locaided.com/auth", formData)
        .then((res) => {
          console.log("You are registered!");
          setFormData({
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            password_confirmation: "",
            phone: "",
            gender: "",
            lat: "",
            long: "",
            code: "",
            date_of_birth: "",
            username: "",
            role: "regular",
            referral_code: "",
          });
          navigate("/login");
        })
        .catch((err) => {
          setError(err.response.data.message);
          setOpenSnackbar(true);
        });
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleContinueWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user && user.access_token) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setGoogleForm((prevForm) => ({
            ...prevForm,
            first_name: res.data.given_name,
            last_name: res.data.family_name,
            email: res.data.email,
          }));
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  useEffect(() => {
    if (googleForm.email !== "") {
      axios
        .post("https://api.locaided.com/auth/sign_in", googleForm)
        .then((res) => {
          if (res.status === 200 && res.data.headers) {
            console.log("User Login Successfully!!!");
            console.log(res);
            const accessToken = res.data.headers["access-token"];
            const tokenType = res.data.headers["token-type"];
            const client = res.data.headers["client"];
            const expiry = res.data.headers["expiry"];
            const uid = res.data.headers["uid"];
            localStorage.setItem("access-token", accessToken);
            localStorage.setItem("token-type", tokenType);
            localStorage.setItem("client", client);
            localStorage.setItem("expiry", expiry);
            localStorage.setItem("uid", uid);
            setGoogleForm({
              first_name: "",
              last_name: "",
              email: "",
              social_media_login: 1,
              login_with_google: true,
            });
            navigate("/dashboard");
          } else {
            setError(res.data.message);
            setOpenSnackbar(true);
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
        });

      const accessToken = localStorage.getItem("access-token");
      if (!accessToken) {
        navigate("/signup");
      }
    }
  }, [googleForm, navigate]);

  const handleContinueWithApple = () => {
    alert("Continue with Apple clicked");
  };

  const smallScreen = useMediaQuery("(max-width:600px)");

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="100vw"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          padding: smallScreen ? "2rem" : 0,
        }}
      >
        <div style={{ width: "100%", maxWidth: "380px", textAlign: "center" }}>
          <Box
            style={{
              textAlign: "left",
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
          >
            <img
              src={darkMode ? logoLight : logoDark}
              alt="Locaided Logo"
              style={{
                width: "100%",
                maxWidth: "60%",
                height: "auto",
              }}
            />
            <Typography
              component="h6"
              variant="h4"
              sx={{
                fontWeight: "bold",
                fontFamily: "Urbanist",
                mt: "2rem",
                mb: "2rem",
                padding: "0",
              }}
            >
              Create Your Account
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} justifyContent="center">
              {currentPage === 1 ? (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: "5px", paddingTop: "5px" }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="email"
                        type="email"
                        autoComplete="email"
                        placeholder="Email"
                        value={formData.email}
                        error={Boolean(emailError)}
                        helperText={emailError}
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon sx={{ color: "#9E9E9E" }} />
                            </InputAdornment>
                          ),
                          sx: {
                            backgroundColor: theme.palette.grayish.main,
                            "&:focus": {
                              borderColor: theme.palette.primary.main,
                            },
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            mb: "0.5rem",
                            height: "3.5rem",
                          },
                          "& .MuiOutlinedInput-input::placeholder": {
                            fontFamily: "Urbanist",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: "5px", paddingTop: "5px" }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        autoComplete="new-password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockSVG width="20px" height="20px" />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={togglePasswordVisibility}
                                edge="end"
                                aria-label="toggle password visibility"
                                size="small"
                              >
                                {showPassword ? (
                                  <VisibilityOff sx={{ color: "#9E9E9E" }} />
                                ) : (
                                  <Visibility sx={{ color: "#9E9E9E" }} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          sx: {
                            backgroundColor: theme.palette.grayish.main,
                            "&:focus": {
                              borderColor: theme.palette.primary.main,
                            },
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            mb: "0.5rem",
                            height: "3.5rem",
                          },
                          "& .MuiOutlinedInput-input::placeholder": {
                            fontFamily: "Urbanist",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: "5px", paddingTop: "5px" }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="password_confirmation"
                        type={showPassword ? "text" : "password"}
                        autoComplete="new-password"
                        placeholder="Confirm Password"
                        value={formData.password_confirmation}
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockSVG width="20px" height="20px" />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={togglePasswordVisibility}
                                edge="end"
                                aria-label="toggle password visibility"
                                size="small"
                              >
                                {showPassword ? (
                                  <VisibilityOff sx={{ color: "#9E9E9E" }} />
                                ) : (
                                  <Visibility sx={{ color: "#9E9E9E" }} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          sx: {
                            backgroundColor: theme.palette.grayish.main,
                            "&:focus": {
                              borderColor: theme.palette.primary.main,
                            },
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            mb: "0.5rem",
                            height: "3.5rem",
                          },
                          "& .MuiOutlinedInput-input::placeholder": {
                            fontFamily: "Urbanist",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      sx={{ pb: 2 }}
                      control={
                        <Checkbox
                          checked={isAgreed}
                          onChange={handleCheckboxChange}
                          sx={{
                            color: theme.palette.primary.main,
                            "& .Mui-checked": {
                              color: "#ff2557",
                            },
                          }}
                          inputProps={{ "aria-label": "agree to terms" }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          textAlign="left"
                          fontFamily="Urbanist"
                        >
                          By registering, you agree to the{" "}
                          <Link href="/#" underline="hover" color="#2196F3">
                            Terms and Conditions, Privacy Policy
                          </Link>
                          , and the{" "}
                          <Link href="/#" underline="hover" color="#2196F3">
                            use of cookies.
                          </Link>
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: "5px", paddingTop: "5px" }}
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      style={{
                        borderRadius: "50px",
                        fontFamily: "Urbanist",
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        "&:hover": {
                          backgroundColor: theme.palette.primary.main,
                        },
                        height: "3rem",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <b>Next</b>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: "5px", paddingTop: "5px" }}
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      style={{
                        borderRadius: "50px",
                        height: "3rem",
                        fontFamily: "Urbanist",
                        marginBottom: "0.5rem",
                        color: theme.palette.matteBlack.main,
                        backgroundColor: "#fff",
                      }}
                      startIcon={<AppleIcon />}
                      onClick={handleContinueWithApple}
                    >
                      <b>Continue with Apple</b>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: "5px", paddingTop: "5px" }}
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      style={{
                        borderRadius: "50px",
                        height: "3rem",
                        marginBottom: "0.5rem",
                        fontFamily: "Urbanist",
                        color: theme.palette.grayish.main,
                        backgroundColor: "#0096FF",
                      }}
                      startIcon={<GoogleIcon />}
                      onClick={handleContinueWithGoogle}
                    >
                      <b>Continue with Google</b>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: "5px", paddingTop: "5px" }}
                  >
                    <Typography
                      variant="body2"
                      mb="0.5rem"
                      fontFamily="Urbanist"
                    >
                      Already have an account?{" "}
                      <span
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                          color: "#ff2557",
                        }}
                        onClick={() => navigate("/login")}
                      >
                        Login
                      </span>
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: "5px", paddingTop: "5px" }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="first_name"
                        type="text"
                        autoComplete="given-name"
                        placeholder="First Name"
                        value={formData.first_name}
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon sx={{ color: "#9E9E9E" }} />
                            </InputAdornment>
                          ),
                          sx: {
                            backgroundColor: theme.palette.grayish.main,
                            "&:focus": {
                              borderColor: theme.palette.primary.main,
                            },
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            mb: "0.5rem",
                            height: "3.5rem",
                          },
                          "& .MuiOutlinedInput-input::placeholder": {
                            fontFamily: "Urbanist",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: "5px", paddingTop: "5px" }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="last_name"
                        type="text"
                        autoComplete="family-name"
                        placeholder="Last Name"
                        value={formData.last_name}
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon sx={{ color: "#9E9E9E" }} />
                            </InputAdornment>
                          ),
                          sx: {
                            backgroundColor: theme.palette.grayish.main,
                            "&:focus": {
                              borderColor: theme.palette.primary.main,
                            },
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            mb: "0.5rem",
                            height: "3.5rem",
                          },
                          "& .MuiOutlinedInput-input::placeholder": {
                            fontFamily: "Urbanist",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: "5px", paddingTop: "5px" }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="username"
                        type="text"
                        autoComplete="username"
                        placeholder="User Name"
                        value={formData.username}
                        onChange={handleChange}
                        error={Boolean(userError)}
                        helperText={userError}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon sx={{ color: "#9E9E9E" }} />
                            </InputAdornment>
                          ),
                          sx: {
                            backgroundColor: theme.palette.grayish.main,
                            "&:focus": {
                              borderColor: theme.palette.primary.main,
                            },
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            mb: "0.5rem",
                            height: "3.5rem",
                          },
                          "& .MuiOutlinedInput-input::placeholder": {
                            fontFamily: "Urbanist",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ paddingLeft: "5px", paddingTop: "5px" }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="code"
                        autoComplete="tel-country-code"
                        placeholder="Country"
                        value={formData.code}
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Flag width="20px" height="20px" />
                            </InputAdornment>
                          ),
                          sx: {
                            backgroundColor: theme.palette.grayish.main,
                            "&:focus": {
                              borderColor: theme.palette.primary.main,
                            },
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            mb: "0.5rem",
                            height: "3.5rem",
                          },
                          "& .MuiOutlinedInput-input::placeholder": {
                            fontFamily: "Urbanist",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    style={{ paddingLeft: "5px", paddingTop: "5px" }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="phone"
                        type="tel"
                        autoComplete="tel"
                        placeholder="Phone Number"
                        value={formData.phone}
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneIcon sx={{ color: "#9E9E9E" }} />
                            </InputAdornment>
                          ),
                          sx: {
                            backgroundColor: theme.palette.grayish.main,
                            "&:focus": {
                              borderColor: theme.palette.primary.main,
                            },
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            mb: "0.5rem",
                            height: "3.5rem",
                          },
                          "& .MuiOutlinedInput-input::placeholder": {
                            fontFamily: "Urbanist",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: "5px", paddingTop: "5px" }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="date_of_birth"
                        type="date"
                        autoComplete="bday"
                        placeholder="Date of Birth"
                        value={formData.date_of_birth}
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CalendarTodayOutlinedIcon
                                sx={{ color: "#9E9E9E" }}
                              />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <ArrowDropDownIcon
                                sx={{ color: "#9E9E9E" }}
                                onClick={() =>
                                  document
                                    .getElementsByName("date_of_birth")[0]
                                    .showPicker()
                                }
                              />
                            </InputAdornment>
                          ),
                          sx: {
                            backgroundColor: theme.palette.grayish.main,
                            "&:focus": {
                              borderColor: theme.palette.primary.main,
                            },
                          },
                          style: {
                            appearance: "none",
                            width: "100%",
                            cursor: "pointer",
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            mb: "0.5rem",
                            height: "3.5rem",
                          },
                          "& .MuiOutlinedInput-input::placeholder": {
                            fontFamily: "Urbanist",
                          },
                          "& input[type='date']::-webkit-calendar-picker-indicator":
                            {
                              display: "none",
                            },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: "5px", paddingTop: "5px" }}
                  >
                    <FormControl fullWidth>
                      <Select
                        fullWidth
                        variant="outlined"
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        displayEmpty
                        input={
                          <OutlinedInput
                            startAdornment={
                              <InputAdornment position="start">
                                <PersonIcon sx={{ color: "#9E9E9E" }} />
                              </InputAdornment>
                            }
                          />
                        }
                        style={{
                          height: "3.5rem",
                          marginBottom: "0.5rem",
                          textAlign: "left",
                          color: "#9E9E9E",
                          fontFamily: "Urbanist",
                          backgroundColor: theme.palette.grayish.main,
                        }}
                      >
                        <MenuItem value="" disabled>
                          Gender
                        </MenuItem>
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: "5px", paddingTop: "5px" }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="referral_code"
                        type="text"
                        placeholder="Referral Code (optional)"
                        value={formData.referral_code}
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <TaskAltIcon sx={{ color: "#9E9E9E" }} />
                            </InputAdornment>
                          ),
                          sx: {
                            backgroundColor: theme.palette.grayish.main,
                            "&:focus": {
                              borderColor: theme.palette.primary.main,
                            },
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            mb: "0.5rem",
                            height: "3.5rem",
                          },
                          "& .MuiOutlinedInput-input::placeholder": {
                            fontFamily: "Urbanist",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      sx={{ pb: 2 }}
                      control={
                        <Checkbox
                          checked={isAgreed}
                          onChange={handleCheckboxChange}
                          sx={{
                            color: theme.palette.primary.main,
                            "& .Mui-checked": {
                              color: "#ff2557",
                            },
                          }}
                          inputProps={{ "aria-label": "agree to terms" }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          textAlign="left"
                          fontFamily="Urbanist"
                        >
                          By registering, you agree to the{" "}
                          <Link href="/#" underline="hover" color="#2196F3">
                            Terms and Conditions, Privacy Policy
                          </Link>
                          , and the{" "}
                          <Link href="/#" underline="hover" color="#2196F3">
                            use of cookies.
                          </Link>
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: "5px", paddingTop: "5px" }}
                  >
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      style={{
                        borderRadius: "50px",
                        fontFamily: "Urbanist",
                        backgroundColor: isAgreed
                          ? theme.palette.primary.main
                          : theme.palette.primary.light,
                        color: "white",
                        "&:hover": {
                          backgroundColor: isAgreed
                            ? theme.palette.primary.light
                            : theme.palette.primary.main,
                        },
                        height: "3rem",
                        marginBottom: "0.5rem",
                      }}
                      disabled={!isAgreed}
                    >
                      <b>Sign Up</b>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: "5px", paddingTop: "5px" }}
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleBack}
                      style={{
                        fontFamily: "Urbanist",
                        borderRadius: "50px",
                        backgroundColor: "#fff",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "#fff",
                        },
                        height: "3rem",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <b>Back</b>
                    </Button>
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={12}
                style={{ paddingLeft: "5px", paddingTop: "5px" }}
              >
                <div className="container">
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                      <Link
                        href="#"
                        color="inherit"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography variant="caption" fontFamily="Urbanist">
                          Terms and Conditions
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        href="#"
                        color="inherit"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography variant="caption" fontFamily="Urbanist">
                          Privacy Policy
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        href="#"
                        color="inherit"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography variant="caption" fontFamily="Urbanist">
                          Imprint
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        href="#"
                        color="inherit"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography variant="caption" fontFamily="Urbanist">
                          Cookie Policy
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </form>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={5000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity="error"
              sx={{ width: "100%" }}
            >
              {error}
            </Alert>
          </Snackbar>
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default SignupForm;
