import React from "react";
import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const DeactivateAccount = () => {
  const navigate = useNavigate();
  const { user } = useOutletContext();

  return (
    <Box style={{ maxWidth: 560, padding: "10px" }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" ml={2}>
          Deactivate your Account
        </Typography>
      </Box>
      <Box sx={{ padding: "2rem 1rem 0 1rem" }}>
        <Box
          ml={1}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Avatar
            alt="Max Schmidt"
            src={user.profile_image_url.sharable}
            sx={{ width: 60, height: "auto", border: "4px solid #fff" }}
          />
          <Typography variant="h6" ml={2} display="flex" flexDirection="column">
            {user.full_name}
            <span style={{ fontSize: "13px" }}>{user.username}</span>
          </Typography>
        </Box>
        <Typography
          variant="subtitle2"
          ml={1}
          mt={3}
          sx={{ fontSize: "13px", fontWeight: "bold" }}
        >
          This will deactivate your Locaided account
        </Typography>
        <Typography variant="body2" ml={1} mt={2} sx={{ fontSize: "13px" }}>
          You're close to beginning the process to turn off your Locaided
          account.
          <br />
          <br />
          Your display name, @username, and public profile will no longer be
          visible on locaided.com, Locaided for iOS, or Locaided for Android.
        </Typography>
        <Typography
          variant="subtitle2"
          mt={2}
          ml={1}
          sx={{ fontSize: "13px", fontWeight: "bold" }}
        >
          Additional information to Note
        </Typography>
        <Typography variant="body2" ml={1} sx={{ fontSize: "13px" }}>
          Your Locaided account can be restored if it's accidentally or
          improperly deactivated, within a span of 30 days post deactivation.
          <br />
          <br />
          If you have any ongoing paid subscriptions (for instance, Premium)
          procured through the Locaided app, they will stay active.
          <br />
          <br />
          You can administer these subscriptions via the platform from where you
          originally subscribed. Subscriptions adopted on locaided.com will
          automatically be ceased after you deactivate your account.
          <br />
          <br />
          There's a possibility some account information may still be accessible
          in search engines like Google or Bing.
          <br />
          <br />
          If you merely want to alter your @username, there's no requirement to
          deactivate your account — modify it in your settings.
          <br />
          <br />
          To utilize your present @username or email in combination with a
          different account, adjust them before you deactivate this one.
        </Typography>
        <Box display="flex" justifyContent="center" mt={3}>
        <Button
          sx={{
            color: "#ff2557",
            "&:hover": {
              color:"#fff",
              backgroundColor: "#e0224f",
            },
          }}
        >
          Deactivate
        </Button>
      </Box>
      </Box>
    </Box>
  );
};

export default DeactivateAccount;
