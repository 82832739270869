import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  InputBase,
  InputAdornment,
  Paper,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";

const ChatList = ({ onSelectChat }) => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchChats = () => {
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");

    axios
      .get("https://api.locaided.com/v1/users/get_all_chats", {
        headers: {
          "access-token": accessToken,
          "token-type": tokenType,
          client: client,
          expiry: expiry,
          uid: uid,
        },
      })
      .then((response) => {
        setChats(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching chats:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchChats();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CircularProgress size={50} sx={{ color: "#FD445D" }} />
        <Typography variant="h6" style={{ marginTop: "18px" }}>
          Loading...
        </Typography>
      </Box>
    );
  }
  return (
    <div style={{ padding: "10px" }}>
      <h3>Messages</h3>
      <Paper
        style={{
          padding: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          margin: "10px auto",
          borderRadius: "25px",
        }}
      >
        <InputBase
          style={{ marginLeft: 8, flex: 1 }}
          placeholder="Search"
          inputProps={{ "aria-label": "search" }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </Paper>
      <List>
        {chats.map((chat, index) => (
          <ListItem
            button
            key={index}
            onClick={() => onSelectChat(chat.message)}
          >
            <ListItemAvatar>
              <Avatar
                alt={chat.message.opposite_user_full_name}
                src={chat.message.opposite_user_profile_image.sharable}
              />
            </ListItemAvatar>
            <ListItemText primary={chat.message.opposite_user_full_name} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default ChatList;
